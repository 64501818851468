@font-face {
  font-family: "Bebas Neue";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/BebasNeue-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Italic";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Poppins-Italic.ttf");
}
