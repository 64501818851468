@import url("fonts.css");

:root {
  --font: "Poppins";
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* html,
body {
  height: 100%;
  width: 100%;
  font-family: "var(--font)";
  background-color: #fff;
} */

/* .nav-arrow {
  left: 50%;
  margin-left: -9px;
}

.nav-arrow {
  border-style: solid;
  border-width: 9px;
  border-color: transparent;
  border-top-width: 0;
  border-bottom: 9px solid #ccc;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -9px;
} */

.selectedOption path {
  fill: #00cb74;
}

.map-container {
  width: 100%;
  height: 80vh;
  border-radius: 5px;
}

input:focus {
  outline: none;
}

.MuiInputBase-root {
  font-family: var(--font) !important;
}

.card_container:hover .card_product {
  transition: 0.5s all ease-in-out;
  transform: scale(1.1);
}

.dropzone {
  height: 100px;
  width: 100px;
  border: 2px dashed #a6b0cf;
  border-radius: 50%;
}

.categoriesMenu {
  left: 0 !important;
}

.dropzoneCustom {
  height: 100px;
  width: 100px;
  border: 2px dashed #ffffff;
  border-radius: 50%;
}

.iconWhite,
.closeIconWhite {
  color: white;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.all_category {
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  position: absolute;
  flex-direction: column;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1053;
  height: -webkit-fill-available;
}

.category_item {
  max-width: 300px;
  flex-basis: auto;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  overflow-x: hidden;
  /* background-color: #ffffff; */
  padding-bottom: 234px;
  overflow-y: auto;
}

@media (max-width: 500px) {
  .category_item {
    max-width: 500px !important;
  }
}

.body-scroll-disabled {
  overflow: hidden !important;
}

@keyframes moveSubContainer {
  0% {
    transform: translateX(520px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes moveMainContainer {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-520px);
  }
}

a:focus,
button:focus,
button {
  outline: none !important;
  box-shadow: none;
}

.CardPriceLineGrid {
  /* padding: 0px;
  overflow: hidden;
  flex-wrap: nowrap; */
}

.CardPriceLineGrid::before {
  content: "";
  position: absolute;
  width: 94%;
  height: 24px;
  pointer-events: none;
  overflow: hidden;
  background: linear-gradient(to left, white, transparent 25%);
  z-index: 9;
}

@media (min-width: 600px) {
  .CardPriceLineGrid {
    padding: 0px;
    overflow: hidden;
    flex-wrap: nowrap;
  }

  .CardPriceLineGrid::before {
    content: "";
    position: absolute;
    width: 94%;
    height: 24px;
    pointer-events: none;
    overflow: hidden;
    background: linear-gradient(to left, white, transparent 25%);
    z-index: 9;
  }
}

.FilterCardPriceLineGrid {
  padding: 0px;
  flex-wrap: nowrap;
  overflow: hidden;
}

.FilterCardPriceLineGrid::before {
  content: "";
  position: absolute;
  width: 64%;
  height: 28px;
  pointer-events: none;
  overflow: hidden;
  background: linear-gradient(to left, white, transparent 25%);
  z-index: 9;
  right: 0px;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

.MuiTooltip-popperInteractive {
  z-index: 99 !important;
}

.googleMapContainer div {
  border-color: transparent !important;
}
